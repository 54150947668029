import React from 'react';
import Slider from "react-slick/lib";
import {Link} from "gatsby";
import Img from "../../../components/Img";


const WorksLike = ({items, worksLikeSubtitle}) => {
    let slider = React.createRef();

    const slidesToShow = () => {
        switch (items.length) {
            case 1:
                return 1;
            case 2:
                return 2;
            default:
                return 3;
        }
    };


    let settings = {

        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: slidesToShow(),
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: items.length >= 2 ? 2 : 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    let nextSlide = () => {
        slider.current.slickNext();
    };

    let prevSlide = () => {
        slider.current.slickPrev();
    };

    const worksLike = items.map(item => {
        return (
            <Link key={item.id} to={'/portfolio/' + item.subtitle_en.toLocaleLowerCase().replace(/ /g, '-')}>
                <div className="works-like-item">
                    <Img fluid={item.img} alt={item.img.title} className="works-like-item__img"/>
                </div>
            </Link>
        );
    });

    return (


        <div className="works-like">
            <div className="section-head">
                <h2 className="subtitle subtitle_lvl_two">
                    {worksLikeSubtitle}
                </h2>
                {
                    items.length > 3 ?
                        <div className="section-head-button">
                            <button onClick={prevSlide} className="reviews-slider__button reviews-slider__button_prev" aria-label="prev">
                                <svg xmlns="http://www.w3.org/2000/svg" width="93" height="8" viewBox="0 0 93 8" fill="none">
                                    <path d="M0.646447 3.64644C0.451184 3.8417 0.451184 4.15828 0.646447 4.35355L3.82843 7.53553C4.02369 7.73079 4.34027 7.73079 4.53553 7.53553C4.7308 7.34026 4.7308 7.02368 4.53553 6.82842L1.70711 3.99999L4.53553 1.17157C4.7308 0.976303 4.7308 0.659721 4.53553 0.464458C4.34027 0.269196 4.02369 0.269196 3.82843 0.464458L0.646447 3.64644ZM93 3.5L1 3.49999L1 4.49999L93 4.5L93 3.5Z" fill="black"/>
                                </svg>
                            </button>
                            <button onClick={nextSlide} className="reviews-slider__button reviews-slider__button_next" aria-label="next">
                                <svg xmlns="http://www.w3.org/2000/svg" width="93" height="8" viewBox="0 0 93 8" fill="none">
                                    <path d="M92.3536 4.35356C92.5488 4.1583 92.5488 3.84172 92.3536 3.64645L89.1716 0.464474C88.9763 0.269212 88.6597 0.269212 88.4645 0.464474C88.2692 0.659736 88.2692 0.976318 88.4645 1.17158L91.2929 4.00001L88.4645 6.82843C88.2692 7.0237 88.2692 7.34028 88.4645 7.53554C88.6597 7.7308 88.9763 7.7308 89.1716 7.53554L92.3536 4.35356ZM-4.37114e-08 4.5L92 4.50001L92 3.50001L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="black"/>
                                </svg>
                            </button>
                        </div>
                        : null
                }

            </div>
            <Slider {...settings} ref={slider}>
                {worksLike}
            </Slider>
        </div>

    );
};


export default WorksLike;
