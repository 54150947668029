import React, {useState} from 'react';
import {graphql, StaticQuery} from "gatsby";

import WorksLike from '../components/WorksLike';

const WorksLikeContainer = ({idElement, category, worksLikeSubtitle}) => {

    const [worksLike, setWorksLike] = useState([]);

    return (
        <StaticQuery
            query={graphql`
              query {
                allContentfulPortfolioCategory {
                    edges {
                      node {
                        id
                        portfolioitems {
                          id
                            subtitle_ru
                            subtitle_en
                            subtitle_cn
                          img {
                          title
                            gatsbyImageData
                          }
                        }
                      }
                    }
                  }
                  }
              
            `}
            render={data => {

                let worksLikeItems = [];


                data.allContentfulPortfolioCategory.edges.map(item => {
                    category.map(category => {
                        if (item.node.id === category.id) {
                            return setWorksLike(item.node.portfolioitems);
                        }
                    })

                });

                worksLike.map(item => {
                    return item.id !== idElement &&  worksLikeItems.push(item)
                });

                return (
                    <WorksLike items={worksLikeItems.sort((a, b) => {return Math.random() - 0.5;})} worksLikeSubtitle={worksLikeSubtitle}/>
                );
            }}
        />
    );
};


export default WorksLikeContainer;
