import React, { useEffect, useState } from 'react';
import { BigPlayButton, ControlBar, Player } from 'video-react';
import Responsive from 'react-responsive';

import { Modal, PhotoGallery } from '../../../components';
import FormFeedbackModal from '../../FormFeedback/containers/FormFeedbackModal';
import WorksLike from '../../WorksLike/containers/WorksLike';

const Adp = (props) => <Responsive {...props} maxWidth={767} />;
const NotAdp = (props) => <Responsive {...props} minWidth={768} />;

const PortfolioItemsDetail = (props) => {
  const {
    id,
    description,
    descriptionTwo,
    descriptionThree,
    photogallery,
    worksLike,
    worksLikeSubtitle,
    video,
    videoYoutubeView,
    videoYoutubeUrl,
    videoDetail,
    videoPoster,
    buttonName,
    formPage,
    languageSelect,
  } = props;

  const mainVideo = React.createRef();

  const [isOpen, setIsOpen] = useState(false);
  const [videoFullScreen, setVideoFullScreen] = useState(false);

  useEffect(() => {
    if (!videoYoutubeView) {
      mainVideo.current.subscribeToStateChange(handleStateChange.bind(this));
    }
  }, [videoYoutubeView]);

  const handleStateChange = (state, prevState) => {
    setVideoFullScreen(state.isFullscreen);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className='container'>
        <div className='portfolio-detail-text'>{description}</div>
      </div>
      <div className='portfolio-detail-video'>
        <div className='container'>
          <div className='frame service-detail-video__frame'>
            <div className='frame-item frame-item_color_black frame-top'>
              <div className='frame-item__el frame-top__frame-item-el_left'></div>
              <div className='frame-item__el frame-top__frame-item-el_right'></div>
            </div>
            <div className='frame-item frame-item_color_black frame-bottom'>
              <div className='frame-item__el frame-bottom__frame-item-el_left'></div>
              <div className='frame-item__el frame-bottom__frame-item-el_right'></div>
            </div>
          </div>
          <Adp>
            {videoYoutubeView && videoYoutubeUrl ? (
              <iframe
                className={'portfolio-detail-video-youtube'}
                src={`https://www.youtube.com/embed/${videoYoutubeUrl}?autoplay=1&showinfo=0&autohide=1&hl=${languageSelect}`}
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            ) : (
              <Player
                muted={false}
                autobuffer
                playsInline={true}
                src={videoDetail}
                poster={videoPoster}
                ref={mainVideo}
              >
                <ControlBar
                  autoHide={false}
                  disableDefaultControls={true}
                  disableCompletely={true}
                />
                <BigPlayButton position='center' />
              </Player>
            )}
          </Adp>
          <NotAdp>
            {videoYoutubeView && videoYoutubeUrl ? (
              <iframe
                className={'portfolio-detail-video-youtube'}
                src={`https://www.youtube.com/embed/${videoYoutubeUrl}?autoplay=1&playsinline=1&showinfo=0&autohide=1&hl=${languageSelect}`}
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            ) : (
              <Player
                muted={false}
                autoPlay
                loop
                playsInline={true}
                src={videoDetail}
                ref={mainVideo}
              >
                <ControlBar
                  autoHide={true}
                  disableDefaultControls={false}
                  disableCompletely={false}
                />
                <BigPlayButton position='center' />
              </Player>
            )}
          </NotAdp>
        </div>
      </div>
      <div className='container'>
        <div className='portfolio-detail-text'>{descriptionTwo}</div>
        {photogallery ? (
          <PhotoGallery
            className='portfolio-detail__photogallery'
            items={photogallery}
          />
        ) : null}
        <div className='portfolio-detail-text'>{descriptionThree}</div>
        <div className='portfolio-detail-button'>
          <button className='button' onClick={() => setIsOpen(true)}>
            <div className='button-inner'>{buttonName}</div>
          </button>
        </div>
        <WorksLike
          idElement={id}
          category={worksLike}
          worksLikeSubtitle={worksLikeSubtitle}
        />
      </div>
      <Modal title={buttonName} isOpen={isOpen} onCancel={closeModal}>
        <FormFeedbackModal formPage={formPage} />
      </Modal>
    </>
  );
};

export default PortfolioItemsDetail;
