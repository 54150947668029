import React from 'react';
import { connect } from 'react-redux';
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import { LayoutPages, Seo } from '../components';
import PortfolioDetail from '../modules/Portfolio/components/PortfolioItemsDetail';
import {JSON_Parse} from "../utils/FormatUtils";

const PortfolioDetailTemplate = ({ languageSelect, pageContext }) => {
  const breadcrumbs = [
    {
      pathname: '/',
      crumbLabel: `${
        languageSelect === 'ru'
          ? 'Главная'
          : languageSelect === 'en'
          ? 'Home'
          : '首頁'
      }`,
      active: false,
    },
    {
      pathname: '/portfolio/',
      crumbLabel: `${
        pageContext.breadCrumbSubtitle['subtitle_' + languageSelect]
      }`,
      active: false,
    },
    {
      pathname: '',
      crumbLabel: `${pageContext['subtitle_' + languageSelect]}`,
      active: true,
    },
  ];

  return (
    <LayoutPages
      titlePage={pageContext['subtitle_' + languageSelect]}
      breadcrumbs={breadcrumbs}
    >
      <Seo
        title={`${pageContext['subtitle_' + languageSelect]}`}
        description={`${pageContext.seo['description_' + languageSelect]}`}
        keywords={`${pageContext.seo['keywords_' + languageSelect]}`}
      />
      <section className='section portfolio-detail-page'>
        <div className='portfolio-detail'>
          <PortfolioDetail
            id={pageContext.id}
            description={JSON_Parse(
              pageContext['description_' + languageSelect].raw
            )}
            descriptionTwo={JSON_Parse(
              pageContext['description_two_' + languageSelect].raw
            )}
            descriptionThree={JSON_Parse(
              pageContext['description_three_' + languageSelect].raw
            )}
            photogallery={pageContext.photogallery}
            worksLike={pageContext.worksLike}
            worksLikeSubtitle={
              pageContext.worksLikeSubtitle['subtitle_work_' + languageSelect]
            }
            video={pageContext.video.file.url}
            videoYoutubeView={
              languageSelect === 'ru' || languageSelect === 'en' ? true : false
            }
            videoYoutubeUrl={
              pageContext.videoYouTube &&
              pageContext.videoYouTube.split('/').pop()
            }
            videoDetail={pageContext.videoDetail.file.url}
            videoPoster={pageContext.videoPoster}
            buttonName={pageContext.buttonName['button_form_' + languageSelect]}
            formPage={pageContext['subtitle_ru']}
            languageSelect={languageSelect}
          />
        </div>
      </section>
    </LayoutPages>
  );
};

let mapStateToProps = (state) => {
  return {
    languageSelect: state.languageSelect['languageSelect'],
  };
};

export default connect(mapStateToProps)(PortfolioDetailTemplate);
